<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Config" class="mb-3" />
      </b-col>
    </b-row>
    <b-media>
      <section class="py-3">
        <b-row>
          <b-col cols="10">
            <h2 class="mb-2">Config Management</h2>
          </b-col>
          <b-col cols="2">
            <div class="text-center">
              <b-button class="mb-1" @click="showForm" variant="primary">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span>Add Config</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <config-form-create
          @getList="getList"
          ref="configFormCreate"
          :types="types"
        />
        <b-card>
          <b-row>
              <b-col md="2">
                <b-form-group label="Category" label-for="category">
                  <b-form-select v-model="filter.category" :options="categoryOptions" />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Search by key" label-for="key">
                  <b-input v-model="config_key" />
                </b-form-group>
              </b-col>
          </b-row>
          <config-table @getList="getList" :configs="configs" :types="types" />
        </b-card>
      </section>
    </b-media>
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>

<script>
import service from "../service";
import ConfigTable from "./_components/ConfigTable.vue";
import ConfigFormCreate from "./_components/ConfigFormCreate.vue";

export default {
  components: {
    ConfigTable,
    ConfigFormCreate,
  },
  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 50,
      configs: [],
      types: [
        {
          text: "String",
          value: "string",
        },
        {
          text: "Boolean",
          value: "boolean",
        },
        {
          text: "Number",
          value: "number",
        },
        {
          text: "Object",
          value: "object",
        }
      ],
      filter:{},
      categoryOptions:[
        {text: "All", value: null},
        {text: "Item", value:"item"},
        {text: "System", value:"system"},
        {text: "Quest", value: "quest"}
      ],
			config_key: null
    };
  },
  watch: {
    current_page() {
      this.getList();
    },
		config_key(){
			this.filter.key = { $regex: this.config_key, '$options' : 'i' }
			this.getList();
		},
    filter:{
      deep: true,
      handler: function(newVal){
        if(newVal){
          this.getList();
        }
      }
    }
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let response = await service.getList({
        page: this.current_page,
        limit: this.items_perpage,
        query: JSON.stringify(this.filter)
      });
      if (response.data.type === "DATA") {
        this.configs = response.data.data.list;
        this.total_items = response.data.data.total;
      }
    },
    showForm() {
      this.$refs.configFormCreate.showForm();
    },
  },
};
</script>
